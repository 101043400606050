.assements-form {
  overflow-y: scroll;
  height: 60vh;
}

/* make even childs of a div have a different background color */

.assements-form > div:nth-child(even) {
  background-color: #f2f2f2;
}
